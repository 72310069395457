import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Footer from '../../../common components/Footer'
import Mobiletopbanner from './Mobiletopbanner'
import Mobilefirstsection from './Mobilefirstsection'
import Mobileimagesection from './Mobileimagesection'

const Mobilewholepage = () => {
  return (
    <>
    <Topnavbar/>
    <Mobiletopbanner/>
    <Mobilefirstsection/>
    <Mobileimagesection/>
    <Footer/>
    </>
  )
}

export default Mobilewholepage