import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Enterprisetopbanner from './Enterprisetopbanner'
import Enterprisefirstsection from './Enterprisefirstsection'
import Enterpriseimagesection from './Enterpriseimagesection'
import Footer from '../../../common components/Footer'

const Enterprisewholepage = () => {
  return (
    <>
    <Topnavbar/>
    <Enterprisetopbanner/>
    <Enterprisefirstsection/>
    <Enterpriseimagesection/>
    <Footer/>
    </>
  )
}

export default Enterprisewholepage