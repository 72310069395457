import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Itstaffingtopbanner from './Itstaffingtopbanner'
import Itstaffingfirstsection from './Itstaffingfirstsection'
import { Itstaffingimagesection } from './Itstaffingimagesection'
import Footer from '../../../common components/Footer'

const Itstaffingwholepage = () => {
  return (
    <>
    <Topnavbar/>
    <Itstaffingtopbanner/>
    <Itstaffingfirstsection/>
    <Itstaffingimagesection/>
    <Footer/>
    </>
  )
}

export default Itstaffingwholepage