import React from 'react'
import { Row,Col } from 'react-bootstrap'
import cloudimage from "../../../assets/img/cloudsss.jpg"
import { TbSquareRoundedNumber1Filled } from "react-icons/tb";
import { TbSquareRoundedNumber2Filled } from "react-icons/tb";
import { TbSquareRoundedNumber3Filled } from "react-icons/tb";
import { TbSquareRoundedNumber4Filled } from "react-icons/tb";

const Cloudfirstsection = () => {
  return (
    <div className=' m-5 web-text'>
      <Row>
        <Col>
       
    <p style={{textAlign:'justify'}}>We are a leading company specializing in crafting customized cloud applications for businesses of all sizes, boasting a rich seven-year legacy. Our expertise lies in cutting-edge cloud application development services. By harnessing the capabilities of cloud technology, we design innovative and scalable software solutions to propel your business into the digital age.
</p>
<ul type="i">
  <li><TbSquareRoundedNumber1Filled style={{color:"#0d4f8c"}} />Commited unique solutions delivered on time</li>
  <li><TbSquareRoundedNumber2Filled style={{color:"#0d4f8c"}} />Pay-as-you-go pricing ensures cost-effectiveness and scalability for efficient resource management.</li>
  <li><TbSquareRoundedNumber3Filled style={{color:"#0d4f8c"}} />Access everything,anywhere at any time</li>
  <li><TbSquareRoundedNumber4Filled style={{color:"#0d4f8c"}} />Keep your software up-to-date and bug free with 24/7 support</li>
</ul> 
</Col>
<Col>
<img src={cloudimage} style={{borderRadius:"10px"}} />
</Col>

</Row>

</div>
  )
}

export default Cloudfirstsection