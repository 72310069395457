import React from 'react'
import soluptons from "../assets/img/techex-landing-page/we-provide-solutopns-bg.png"


const Howcanhelpyou = () => {
  return (
    <div className='body-wrapper'>

<section className="we-provide-solutions">
            <div className="container" >
                <div className="row align-items-center">
                    <div className="col-12 text-center mt-5">
                        <div className="section-title">
                            <h1 data-aos="fade-up" data-aos-delay="100" style={{marginTop:'-70px'}}>We Provide Best IT Solutions For  7+ Years</h1>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up">
                        <a href="/mobservice" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}> 
                            <div className="number">
                                01
                            </div>
                            <div className="content"> 
                                <h3 >Mobile App Development</h3>
                                <p>Robust, progressive, and hybrid mobile app solutions that best fit your needs..</p>
                                <span className="read-more text-uppercase">read more <i className="icon-arrow-right-1"></i></span>
                            </div>
                        </a>
                    </div>

                    <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up" data-aos-delay="100">
                        <a href="/webservice" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                            <div className="number">
                                02
                            </div>
                            <div className="content"> 
                                <h3>Web App Development</h3>
                                <p>Build a well-constructed website and web application  functionality & interactivity</p>
                                <span className="read-more text-uppercase">read more <i className="icon-arrow-right-1"></i></span>
                            </div>
                        </a>
                    </div>
                    
                    <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up" data-aos-delay="150">
                        <a href="/cloudservice" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                            <div className="number">
                                03
                            </div>
                            <div className="content"> 
                                <h3>Cloud Services</h3>
                                <p>Exceptional real-time solutions and customized cloud solutions according to your specific requirements.</p>
                                <span className="read-more text-uppercase">read more <i className="icon-arrow-right-1"></i></span>
                            </div>
                        </a>
                    </div>
                    
                    <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up" data-aos-delay="200">
                        <a href="/blockchainservice" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                            <div className="number">
                                04
                            </div>
                            <div className="content"> 
                                <h3>Blockchain Services</h3>
                                <p>Unlocking possibilities with blockchain solutions: Your path to innovation and security</p>
                                <span className="read-more text-uppercase">read more <i className="icon-arrow-right-1"></i></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Howcanhelpyou