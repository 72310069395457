import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Homepage from "./Home/Homepage";
import Aboutwholepage from "./About/Aboutwholepage";
import Serviceswholepage from "./Services/Serviceswholepage";
import Casestudywholepage from "./Casestudy/Casestudywholepage";
import Newsfeedwholepage from "./News/Newsfeedwholepage";
import ContactWholePage from "./Contact/ContactWholePage";
import Servicedetailwholepage from "./Services/ServiceDetail/Servicedetailwholepage";
import Projectdetails from "./Projectdetails/Projectdetails";
import Teamdetails from "./Teamdetails/Teamdetails";
import ContactForm, { ContactUs } from "./Contact/Careerpage";
import Newsdetailwholepage from "./News/Newsdetail/Newsdetailwholepage";
import Webwholepage from "./Services/Development/Web/Webwholepage";
import Mobilewholepage from "./Services/Development/Mobile/Mobilewholepage";
import Maintenancewholepage from "./Services/Development/Maintenance/Maintenancewholepage";
import Itstaffingwholepage from "./Services/Development/IT Staffing/Itstaffingwholepage";
import Enterprisewholepage from "./Services/Development/Enterprise/Enterprisewholepage";
import Customwholepage from "./Services/Development/Custom/Customwholepage";
import Cloudwholepage from "./Services/Development/Cloud/Cloudwholepage";
import Blockchainwholepage from "./Services/Development/Blockchain/Blockchainwholepage";


const Routers = () => {
  


  return (
    <>
     
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="about" element={<Aboutwholepage />} />
          <Route path="/services" element={<Serviceswholepage />} />
          <Route path="/Casestudy" element={<Casestudywholepage />} />
          <Route path="/News" element={<Newsfeedwholepage />} />
          <Route path="/contact" element={<ContactWholePage />} />
         
        

          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route path="/servicedetail" element={<Servicedetailwholepage />} />
          <Route path="/Projectdetail" element={<Projectdetails />} />
          <Route path="/Team" element={<Teamdetails />} />
          <Route path="/newsdetail" element={<Newsdetailwholepage/>}/>

          {/* Career Page */}
          <Route path="/careerpage" element={<ContactUs/>}/>
          <Route path="/webservice" element={<Webwholepage/>}/>
          <Route path="/mobservice" element={<Mobilewholepage/>}/>
          <Route path="/maintenanceservice" element={<Maintenancewholepage/>}/>
          <Route path="/itstaffingservice" element={<Itstaffingwholepage/>}/>
          <Route path="/enterpriseservice" element={<Enterprisewholepage/>}/>
          <Route path="/customservice" element={<Customwholepage/>}/>
          <Route path="/cloudservice" element={<Cloudwholepage/>}/>
          <Route path="/blockchainservice" element={<Blockchainwholepage/>}/>

          
          

        </Routes>
      </Router>
    </>
  );
};
export default Routers;