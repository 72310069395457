import React from 'react'
import Topnavbar from '../common components/Topnavbar'
import Howcanhelpyou from './Howcanhelpyou'
import Listofclients from './Listofclients'
import Aboutcompany from './Aboutcompany'
import Whatwedo from './Whatwedo'
import Meetourexpertteam from './Meetourexpertteam'
import Ourcompletedprojects from './Ourcompletedprojects'
import Clienttestimonials from './Clienttestimonials'
import Articlesandtipes from './Articlesandtipes'
import Footer from '../common components/Footer'
import Homepagetopbanner from './Homepagetopbanner'

const Homepage = () => {
  return (
    <div>

        <Topnavbar/>
        <Homepagetopbanner/>
        <Howcanhelpyou/>
        <Listofclients/>
        <Aboutcompany/>
        {/* <Whatwedo/> */}
        {/* <Meetourexpertteam/>
        <Ourcompletedprojects/> */}
        {/* <Clienttestimonials/> */}
        {/* <Articlesandtipes/> */}
        <Footer/>
    </div>
  )
}

export default Homepage