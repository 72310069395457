import React from 'react'
import ip1 from "../assets/img/blog/ip1.jpg"
import logo2 from "../assets/img/praatheelogo.png"
import cta_bg1 from "../assets/img/cta_bg1.png"
import { FaEnvelope } from "react-icons/fa";



const Footer = () => {
  return (
 <body className='body-wrapper'>
     <section className="cta-banner style-3">
        <div className="container-fluid bg-cover section-bg" style={{ backgroundImage: `url(${cta_bg1})`}}>
            <div className="cta-content">
                <div className="row align-items-center">
                    <div className="col-xl-7 text-white col-12 text-center text-xl-left">
                        <h1>Let's Grow Your Business Together</h1>
                    </div>
                    <div className="col-xl-5 col-12">
                        <div className="btn-wraper d-flex flex-wrap justify-content-xl-end mt-2 mt-md-4 mt-xl-0">
                            <a href="/contact" className="theme-btn" style={{textDecoration:"none"}}>Get a quote <i className="fas fa-arrow-right"></i></a>
                            {/* <a href="/servicedetail" className="theme-btn">read more <i className="fas fa-arrow-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
    <footer className="footer-4 footer-wrap">
        <div className="footer-widgets">            
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-xl-3 col-12 pr-xl-4">
                        <div className="single-footer-wid site_footer_widget">
                            <div className="footer-logo">
                                <a href="/">
                                    <img src={logo2} alt="logo"/>
                                </a>
                            </div>
                            <div className="footer-logo-2">
                                <a href="/">
                                    <img src={logo2} alt="logo"/>
                                </a>
                            </div>
                            <p className="mt-4">Innovating for tomorrow's needs, we craft next-gen software solutions with our customers in mind.</p>
                            <div className="social-link mt-30">
                            <h6 style={{color:"#89b3c8"}}>Follow us on social media to stay connected</h6> 
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-linkedin"></i></a>
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-6 col-xl-2 col-12">                        
                        <div className="single-footer-wid">
                            <div className="wid-title">
                                <h4>Company</h4>
                            </div>
                            <ul>
                                <li className='d-flex'><a href="/about">About Us</a></li>
                                <li className='d-flex'><a href="/services">Services</a></li>
                                <li className='d-flex'><a href="/contact">Contact Us</a></li>
                                <li className='d-flex'><a href="/project-details">Career</a></li>
                                {/* <li className='d-flex'><a href="/News">Blog Post</a></li> */}
                            </ul>
                        </div>
                    </div> 
                    <div className="col-md-6 col-xl-3 col-12">                        
                        <div className="single-footer-wid">
                            <div className="">
                                <h4>Stay Connected</h4>
                            </div>
                            <div className="social-link mt-30">
                            <h6 style={{color:"#89b3c8"}}>Join With Our Team</h6>
                             

                            </div>
                            <h6><a href="mailto:hr@praathee.com" className='text-decoration-none'><i class="fa-solid fa-envelope"></i> &nbsp;hr@praathee.com</a></h6>
                            <h6> &nbsp;<a><i className="fa fa-phone"></i> &nbsp;+91 7092484157</a></h6>
                            &nbsp;
                            <h6 style={{color:"#89b3c8"}}>Sales </h6> 
                            <h6><a href="mailto:sales@praathee.com" className='text-decoration-none'><i class="fa-solid fa-envelope"></i> &nbsp;sales@praathee.com</a></h6>
                            <h6> &nbsp;<a><i className="fa fa-phone"></i> &nbsp;+91 90432 04157</a></h6>
                        </div>
                    </div> 
                    <div className="col-md-6 col-xl-3 col-12">
                        <div className="single-footer-wid recent_post_widget">
                            <div className="wid-title">
                                <h4>Contact Us</h4>
                            </div>
                            <div className="recent-post-list">
                                <div className="single-recent-post">
                                    {/* <div className="thumb bg-cover" style={{ backgroundImage: `url(${ip1})`}}></div> */}
                                    {/* <div className="post-data">
                                        <span><i className="fa fa-calendar"></i>24th Nov 2020</span>
                                        <h5><a href="/newsdetail">User’s Perspes Using Story Structure</a></h5>
                                    </div> */}
                                </div>
                                <div className="single-recent-post">
                                    {/* <div className="thumb bg-cover" style={{ backgroundImage: `url(${ip1})`}}></div> */}
                                    <div className="post-data">
                                        <span><i className="fas fa-map-marker-alt"></i>No. 11/4 POOJA GARDEN, <br/>Kalapatti Main Rd, Indira Nagar, <br/>Civil Aerodrome Post, Coimbatore,<br/> Tamil Nadu,India-641014</span>
                                        <h6> &nbsp;<a><i className="fa fa-phone"></i> &nbsp;+91 7092484157</a></h6>
                                        <h6><a href="mailto:support@praathee.com" className='text-decoration-none'><i className="far fa-envelope"></i> &nbsp;support@praathee.com</a></h6>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>

        <div className="footer-bottom text-center">
            <div className="container text-center">
                <div className="footer-bottom-content text-center">
                    <a href="/"> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; © 2024  Praathee Media Pvt Ltd </a>. All Rights Reserved
                </div>
            </div>
        </div>
    </footer>
</body>
  )
}

export default Footer