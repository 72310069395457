import React from 'react'
import { Row,Col } from 'react-bootstrap'
import blockchainparaimage from "../../../assets/img/blockchainparaimage.jpg"
import { TbSquareRoundedNumber1Filled } from "react-icons/tb";
import { TbSquareRoundedNumber2Filled } from "react-icons/tb";
import { TbSquareRoundedNumber3Filled } from "react-icons/tb";
import { TbSquareRoundedNumber4Filled } from "react-icons/tb";

const Blockchainfirstsection = () => {
  return (
    <div className=' m-5 web-text'>
      <Row>
        <Col>
        <p style={{textAlign:'justify'}}>As a leading provider of blockchain development services catering to businesses of all sizes, we offer a comprehensive range of blockchain solutions engineered to empower our clients in leveraging the transformative potential of this revolutionary technology. Our team comprises seasoned blockchain professionals well-versed in the latest trends, techniques, and industry best practices</p>
        <ul type="i">
  <li><TbSquareRoundedNumber1Filled style={{color:"#0d4f8c"}} /> Commited unique solutions delivered on time</li>
  <li><TbSquareRoundedNumber2Filled style={{color:"#0d4f8c"}} /> ideal mobile craft application at a very-effective rate</li>
  <li><TbSquareRoundedNumber3Filled style={{color:"#0d4f8c"}} /></li>
  <li><TbSquareRoundedNumber4Filled style={{color:"#0d4f8c"}} /> keep your software up-to-date and bug free with 24/7 support</li>
</ul> 
        </Col>
        <Col>
        <img src={blockchainparaimage} style={{borderRadius:'10px',opacity:'0.80'}}/>
        </Col>
      </Row>
   

</div>
  )
}

export default Blockchainfirstsection