import React from 'react'
import Topnavbar from "../common components/Topnavbar";
import Footer from "../common components/Footer";

import Aboutcompanyaboutsection from "./Aboutcompanyaboutsection";
import Whychooseus from "./Whychooseus";
import Exclusivemembers from "./Exclusivemembers";
import Counts from "./Counts";
import Popularitservices from "./Popularitservices";
import Aboutsectiontestimonial from "./Aboutsectiontestimonial";
import Listofclientsaboutsection from "./Listofclientsaboutsection";
import Aboutsectiontopbanner from "./Aboutsectiontopbanner";

const Aboutwholepage = () => {
  return (
    <div>
      <Topnavbar/>
      <Aboutsectiontopbanner/>
      <Aboutcompanyaboutsection/>
      <Whychooseus/>
      <Exclusivemembers/>
      {/* <Counts/> */}
      {/* <Popularitservices/> */}
      <Aboutsectiontestimonial/>
      <Listofclientsaboutsection/>
      <Footer/> 
    </div>
  )
}

export default Aboutwholepage







