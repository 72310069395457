import React from 'react'
import { Row,Col } from 'react-bootstrap'
import backgimage from "../../../assets/img/backimage.jpg"
import paraimage from "../../../assets/img/Web-3.jpeg"
import { TbSquareRoundedNumber1Filled } from "react-icons/tb";
import { TbSquareRoundedNumber2Filled } from "react-icons/tb";
import { TbSquareRoundedNumber3Filled } from "react-icons/tb";
import { TbSquareRoundedNumber4Filled } from "react-icons/tb";

const Webfirstsection = () => {
  return (
    <div className=' m-5 web-text' >
      <Row>
        <Col>
        <p style={{textAlign:'justify'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welcome to <span style={{color:'#006EA2'}}>Praathee</span>, a leading web application development company that specializes in creating custom web applications for businesses of all sizes. With over [7+] years of experience, our team of skilled developers and designers have a proven track record of delivering high-quality, scalable, and user-friendly web applications that help our clients achieve their business goals.
</p>
<ul type="i">
  <li><TbSquareRoundedNumber1Filled style={{color:"#0d4f8c"}} /> Commited unique solutions delivered on time</li>
  <li><TbSquareRoundedNumber2Filled style={{color:"#0d4f8c"}} /> ideal mobile craft application at a very-effective rate</li>
  <li><TbSquareRoundedNumber3Filled style={{color:"#0d4f8c"}} /></li>
  <li><TbSquareRoundedNumber4Filled style={{color:"#0d4f8c"}} /> keep your software up-to-date and bug free with 24/7 support</li>
</ul> 
</Col>
<Col>
<img src={paraimage} alt='web page image' style={{borderRadius:'10px'}}/>
</Col>
</Row>

    </div>
  )
}

export default Webfirstsection