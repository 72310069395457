import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImUserTie } from "react-icons/im";
import Topnavbar from '../common components/Topnavbar';
import Footer from '../common components/Footer';
import pagebanner from "../assets/img/karriere1.png"
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaHandshakeSimple } from "react-icons/fa6";
import { BsArrowRightShort } from "react-icons/bs";
const Contactfirstsection = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_f39k6te', 'template_m11hml4', form.current, 'VGxVF5bq8PPu9NB4B')
        .then(
          (result) => {
            console.log(result.text);
            form.current.reset(); // Clear the form fields
            toast.success('Message sent successfully!', {
            
            });
          },
          (error) => {
            console.log(error.text);
            toast.error('Failed to send message. Please try again later.', {
            });
          }
        );
    };
  
  return (
    <div>
        <section class="contact-page-wrap section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="single-contact-card card1">
                        <div class="top-part">
                            <div class="icon">
                            <ImUserTie />
                            </div>
                            <div class="title">
                                <h4>HR  TEAM</h4>
                                {/* <span>Sent mail asap anytime</span> */}
                            </div>
                        </div>
                        <div class="bottom-part">                            
                            <div class="info">
                                <p>hr@praathee.com</p>
                                <p>+91 89256 54157</p>
                            </div>
                            <div class="icon">
                                    <BsArrowRightShort />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="single-contact-card card2">
                        <div class="top-part">
                            <div class="icon">
                            <FaHandshakeSimple />
                            </div>
                            <div class="title">
                                <h4>Sales Team</h4>
                                {/* <span>call us asap anytime</span> */}
                            </div>
                        </div>
                        <div class="bottom-part">                            
                            <div class="info">
                                <p>sales@praathee.com</p>
                                <p>+91 90432 04157</p>
                            </div>
                            <div class="icon">
                                    <BsArrowRightShort />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="single-contact-card card3">
                        <div class="top-part">
                            <div class="icon">
                            <i className="fas fa-map-marker-alt"></i>
                            </div>
                            <div class="title">
                                <h4>Our Address</h4>
                                {/* <span>Do mail  anytime</span> */}
                            </div>
                        </div>
                        <div class="bottom-part">                            
                            <div class="info">
                                <p>Innovspace Business Center</p>
                                <p> Coimbatore,India</p>
                            </div>
                            <div class="icon">
                                    <BsArrowRightShort />
                            </div>  
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
      <div className="row section-padding pb-0 ">
        <div className="col-12 text-center mb-20">
          <div className="section-title">
            <p>Send us a message</p>
            <h1>Don’t Hesitate To Contact Us <br/> Say Hello or Message</h1>
          </div>
        </div>

        <div className="col-12 col-lg-12">
          <div className="contact-form">                                                        
            <form action="" className="row conact-form" ref={form} onSubmit={sendEmail}>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="fname">Full Name</label>
                  <input type="text" id="fname" placeholder="Enter Name" name="user_name" required />                                         
                </div>
              </div>                            
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="email">Email Address</label>
                  <input type="email" id="email" placeholder="Enter Email Address" name="user_email" required />                                         
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="phone">Phone Number</label>
                  <input type="text" id="phone" placeholder="Enter Number" number="user_number" required />                                         
                </div>
              </div>                                      
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" id="subject" placeholder="Enter Subject" subject="user_subject" required />                                         
                </div>
              </div>                                      
              <div className="col-md-12 col-12">
                <div className="single-personal-info">
                  <label htmlFor="message">Enter Message</label>
                  <textarea id="message" placeholder="Enter message" message="message" required></textarea>                                        
                </div>
              </div>                                      
              <div className="col-md-12 col-12 text-center">
                <button type="submit" className="theme-btn">Send Message <i className="fas fa-arrow-right"></i></button>
              </div>                                      
            </form>
          </div>
        </div>
      </div>
      </div>

            {/* <div class="row section-padding pb-0">
                <div class="col-12 text-center mb-20">
                    <div class="section-title">
                        <p>send us  message</p>
                        <h1>Don’t Hesited To Contact Us <br/> Say Hello or Message</h1>
                    </div>
                </div>

                <div class="col-12 col-lg-12">
                    <div class="contact-form">                                                        
                        <form action="" class="row conact-form">
                            <div class="col-md-6 col-12">
                                <div class="single-personal-info">
                                    <label for="fname">Full Name</label>
                                    <input type="text" id="fname" placeholder="Enter Name" />                                         
                                </div>
                            </div>                            
                            <div class="col-md-6 col-12">
                                <div class="single-personal-info">
                                    <label for="email">Email Address</label>
                                    <input type="email" id="email" placeholder="Enter Email Address" />                                         
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="single-personal-info">
                                    <label for="phone">Phone Number</label>
                                    <input type="text" id="phone" placeholder="Enter Number"/>                                         
                                </div>
                            </div>                                      
                            <div class="col-md-6 col-12">
                                <div class="single-personal-info">
                                    <label for="subject">Subject</label>
                                    <input type="text" id="subject" placeholder="Enter Subject"/>                                         
                                </div>
                            </div>                                      
                            <div class="col-md-12 col-12">
                                <div class="single-personal-info">
                                    <label for="subject">Enter Message</label>
                                    <textarea id="subject" placeholder="Enter message"></textarea>                                        
                                </div>
                            </div>                                      
                            <div class="col-md-12 col-12 text-center">
                                <button type="submit" class="theme-btn">send  message <i class="fas fa-arrow-right"></i></button>
                            </div>                                      
                        </form>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
    </div>
  )
}

export default Contactfirstsection