import React from 'react'
import testibg from "../assets/img/feedback2.jpg"
import testi1 from "../assets/img/abi.jpg"
import testi2 from "../assets/img/sajath.jpg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const Aboutsectiontestimonial = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          }
        ]
      };

  return (
    <div className='body-wrapper'>
        <section class="testimonial-wrapper section-padding">
        <div class="testimonial-bg bg-cover" style={{backgroundImage:`url(${testibg})`}}></div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-4 col-xl-5 offset-xl-7">
                    
                    <div class="testimonial-carousel-active owl-carousel owl-theme">
                        <Slider {...settings}>

                        <div class="single-testimonial">
                            <div class="icon">
                                <i class="flaticon-right-quote"></i>
                            </div>
                            <h2>As a fresher, I was initially nervous about the working world, but Praathee has been a game-changer for me. I was embraced by their collaborative approach, where everyone's voice matters, from day one. Grateful to be part of this down-to-earth and friendly environment..</h2>
                            <div class="client-info">
                                <div class="client-img bg-cover" style={{backgroundImage:`url(${testi1})`}}></div>
                                <div class="client-bio">
                                    <h3>Abinaya Selvaraj</h3>
                                    <p>Software Tester</p>
                                </div>
                            </div>
                        </div>

                        <div class="single-testimonial">
                            <div class="icon">
                                <i class="flaticon-right-quote"></i>
                            </div>
                            <h2>Working here for an year, I've experienced top-notch learning and a friendly atmosphere.The team here goes an extra mile to ensure professional growth,making it easy to ask questions and level up myself,Also we enjoy team activities, including relaxing lunches.</h2>
                            <div class="client-info">
                                <div class="client-img bg-cover" style={{backgroundImage:`url(${testi2})`}}></div>
                                <div class="client-bio">
                                    <h3>Shajadh Salim</h3>
                                    <p>Software Developer</p>
                                </div>
                            </div>
                        </div>
                        </Slider>

                    </div>
                </div>
            </div>
        </div>
    </section>  
    </div>
  )
}

export default Aboutsectiontestimonial