import React from 'react'
import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.png"
import { Row,Col} from 'react-bootstrap'



const Maintenanceimagesection = () => {
  return (
    <Row className="m-5">
    <Row >
        
        <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                        {/* <div className="number">
                            01
                        </div> */}
                        <div className="content"> 
                            <h3>Regular Maintenance</h3>
                            <hr/>
                            <p>Our team ensures your software stays current and secure with regular updates, patches, and proactive issue monitoring, minimizing downtime and disruptions</p>
                            
                        </div>
                    </a>
                </div>
       
      
        <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                        {/* <div className="number">
                            02
                        </div> */}
                        <div className="content"> 
                            <h3>Technical Support</h3>
                            <hr/>
                            <p>We provide dedicated technical support for all software-related queries, including troubleshooting, bug fixes, and feature guidance, ensuring you're supported every step of the way.</p>
                          
                        </div>
                    </a>
                </div>
     
        
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                     
                        <div className="content"> 
                            <h3>Efficient Optimization</h3>
                            <hr/>
                            <p>We optimize your software to improve performance,efficiency,reliability and maximizing its value,which gives you edge over other slow service,yielding many customers.</p>
                           
                        </div>
                    </a>
                </div>
               
        
    </Row>
    <Row>
    
    <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                        {/* <div className="number">
                            04
                        </div> */}
                        <div className="content"> 
                            <h3>Adaptation and Upgrades</h3>
                            <hr/>
                            <p>We evolve your software as your business grows,ensuring it remains competitive,excel in feature integration and  migration, transitions are seamless.</p>
                           
                        </div>
                    </a>
                </div>
        <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                        {/* <div className="number">
                            05
                        </div> */}
                        <div className="content"> 
                            <h3>Maintenance and support</h3>
                            <hr/>
                            <p>With a team of seasoned professionals, we offer maintenance to prevent issues before they arise, support to swiftly address any unexpected challenges. </p>
                           
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                     
                        <div className="content"> 
                            <h3>Backup and Disaster Recovery</h3>
                            <hr/>
                            <p>We deploy tailored backup and disaster recovery solutions for data protection, ensuring business continuity during outages or disasters.</p>
                           
                        </div>
                    </a>
                </div>

      
        
    </Row>

</Row>
  )
}

export default Maintenanceimagesection