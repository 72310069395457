import React from 'react';
import logo from "../assets/img/praatheelogo.png";
import logo2 from "../assets/img/praatheelogo.png";
import { IoMenu } from "react-icons/io5";
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation } from 'react-router-dom';
import { RiArrowDownSLine } from "react-icons/ri";

const Topnavbar = () => {
    const [show, setShow] = useState(false);
    const location = useLocation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <body className='body-wrapper'>
            <header className="header-wrap header-5 sticky-top">   
                <div className="top-header d-none d-md-block">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="header-cta">
                                    <ul>
                                        <li><a href="#" style={{fontSize:"12px"}}><i className="fas fa-map-marker-alt"></i>Innovspace Business Center, Coimbatore-641014</a></li>
                                        <li><a href="mailto:support@praathee.com" style={{fontSize:"12px"}}><i className="far fa-envelope"></i>support@praathee.com</a></li>
                                        <li><a href="tel:+917092484157" style={{fontSize:"12px"}}><i className="fa fa-phone"></i>+91 7092484157</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="header-right-cta d-flex justify-content-end">
                                    <div className="social-profile">
                                        <a href="#"><i className="fab fa-facebook"></i></a>
                                        <a href="#"><i className="fab fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-header-wraper">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between" style={{height:'70px'}}>
                            <div className="header-logo">
                                <div className="logo">
                                    <a href="/">
                                        <img src={logo} alt="logo"/>
                                    </a>
                                </div>
                                <div className="logo-2">
                                    <a href="/">
                                        <img src={logo2} alt="logo"/>
                                    </a>
                                </div>
                            </div>
                            <div className="header-menu d-none d-xl-block">
                                <div className="main-menu">
                                    <ul className='my-auto'>
                                        <li>
                                            <a href="/" style={{textDecoration:'none', color: location.pathname === '/' ? '#086ad7' : '',fontWeight:location.pathname === '/'? 'bold' : '' }}>Home</a>
                                        </li>
                                        <li>
                                            <a href="/about" style={{textDecoration:'none', color: location.pathname === '/about' ? '#086ad7' : '',fontWeight:location.pathname === '/about'? 'bold' : ''}}>About</a>
                                        </li>
                                        <li>
                                        <a href="#" style={{textDecoration:'none',color: location.pathname === '/services' || location.pathname.startsWith('/webservice') || location.pathname.startsWith('/mobservice') || location.pathname.startsWith('/enterpriseservice') || location.pathname.startsWith('/blockchainservice') || location.pathname.startsWith('/cloudservice') || location.pathname.startsWith('/customservice') || location.pathname.startsWith('/maintenanceservice') || location.pathname.startsWith('/itstaffingservice') ? '#086ad7' : '',fontWeight: location.pathname === '/services' || location.pathname.startsWith('/webservice') || location.pathname.startsWith('/mobservice') || location.pathname.startsWith('/enterpriseservice') || location.pathname.startsWith('/blockchainservice') || location.pathname.startsWith('/cloudservice') || location.pathname.startsWith('/customservice') || location.pathname.startsWith('/maintenanceservice') || location.pathname.startsWith('/itstaffingservice') ? 'bold' : ''}}>Services</a>
                                            <ul className="sub-menu">
                                                <li><a href="/webservice" style={{textDecoration:'none'}}>Web Application Development</a></li>
                                                <li><a href="/mobservice" style={{textDecoration:'none'}}>Mobile Application Development</a></li>
                                                <li><a href="/enterpriseservice" style={{textDecoration:'none'}}>Enterprise Software Development</a></li>
                                                <li><a href="/blockchainservice" style={{textDecoration:'none'}}>Block Chain Development</a></li>
                                                <li><a href="/cloudservice" style={{textDecoration:'none'}}>Cloud Application Development</a></li>
                                                {/* <li><a href="/customservice" style={{textDecoration:'none'}}>Custom Software Development</a></li> */}
                                                <li><a href="/maintenanceservice" style={{textDecoration:'none'}}>Software Maintenance & Support</a></li>
                                                <li><a href="/itstaffingservice" style={{textDecoration:'none'}}>IT Staffing & Support</a></li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a href="/careerpage" style={{textDecoration:'none', color: location.pathname === '/careerpage' ? '#086ad7' : '',fontWeight:location.pathname === '/careerpage'? 'bold' : ''}}>Career</a>
                                        </li>
                                        <li>
                                            <a href="/contact" style={{textDecoration:'none', color: location.pathname === '/contact' ? '#086ad7' : '',fontWeight:location.pathname === '/contact'? 'bold' : ''}}>Contact</a>
                                        </li>
                                      
                                    </ul>
                                </div>
                            </div>
                            <div className="header-right d-flex align-items-center">
                                {/* <div className="header-btn-cta">
                                    <a href="/contact" className="theme-btn">Consultancy <i className="icon-arrow-right-1"></i></a>
                                </div> */}
                                <div className="mobile-nav-bar d-block ml-3 ml-sm-4 d-xl-none">
                                    <div className="mobile-nav-wrap">                    
                                        <div id="hamburger">
                                            <IoMenu className='' style={{cursor:'pointer'}} onClick={handleShow}/>
                                        </div>
                                        <Offcanvas className="mobile-nav" show={show} onHide={handleClose}>
                                            <div className="logo d-flex justify-content-between">
                                                <a href="/">
                                                    <img src={logo} alt="logo"/>
                                                </a>
                                                <Offcanvas.Header closeButton/>
                                            </div>
                                            <nav className="sidebar-nav">
                                                <ul className="metismenu" id="mobile-menu">
                                                    <li><a className="has-arrow" href="#">Home</a></li>
                                                    <li><a href="/about">About</a></li>
                                                    <li>
                                        <a href="#" style={{textDecoration:'none',color: location.pathname === '/services' || location.pathname.startsWith('/webservice') || location.pathname.startsWith('/mobservice') || location.pathname.startsWith('/enterpriseservice') || location.pathname.startsWith('/blockchainservice') || location.pathname.startsWith('/cloudservice') || location.pathname.startsWith('/customservice') || location.pathname.startsWith('/maintenanceservice') || location.pathname.startsWith('/itstaffingservice') ? '#086ad7' : '',fontWeight: location.pathname === '/services' || location.pathname.startsWith('/webservice') || location.pathname.startsWith('/mobservice') || location.pathname.startsWith('/enterpriseservice') || location.pathname.startsWith('/blockchainservice') || location.pathname.startsWith('/cloudservice') || location.pathname.startsWith('/customservice') || location.pathname.startsWith('/maintenanceservice') || location.pathname.startsWith('/itstaffingservice') ? 'bold' : ''}}>Services</a>
                                            <ul className="sub-menu">
                                                <li><a href="/webservice" style={{textDecoration:'none'}}>Web Application Development</a></li>
                                                <li><a href="/mobservice" style={{textDecoration:'none'}}>Mobile Application Development</a></li>
                                                <li><a href="/enterpriseservice" style={{textDecoration:'none'}}>Enterprise Software Development</a></li>
                                                <li><a href="/blockchainservice" style={{textDecoration:'none'}}>Block Chain Development</a></li>
                                                <li><a href="/cloudservice" style={{textDecoration:'none'}}>Cloud Application Development</a></li>
                                                {/* <li><a href="/customservice" style={{textDecoration:'none'}}>Custom Software Development</a></li> */}
                                                <li><a href="/maintenanceservice" style={{textDecoration:'none'}}>Software Maintenance & Support</a></li>
                                                <li><a href="/itstaffingservice" style={{textDecoration:'none'}}>IT Staffing & Support</a></li>

                                            </ul>
                                        </li>
                                        <li><a href="/careerpage">Career</a></li>
                                                    <li><a href="/contact">Contact</a></li>
                                                    
                                                </ul>
                                            </nav>
                                            <div className="action-bar">
                                                <a href="#"><i className="fas fa-map-marker-alt"></i>Innovspace Business Center, Coimbatore-641014</a>
                                                <a href="mailto:support@praathee.com"><i className="far fa-envelope"></i>support@praathee.com</a>
                                                <a href="tel:+917092484157"><i className="fa fa-phone"></i>+91 7092484157</a>
                                                {/* <a href="/contact" className="d-btn theme-btn black">Consultancy <i className="icon-arrow-right-1"></i></a> */}
                                            </div>
                                        </Offcanvas>
                                    </div>
                                    <div className="overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="header-gutter"></div>
        </body>
    );
};

export default Topnavbar;
