import React from 'react'
import { Row,Col } from 'react-bootstrap'
import erpimage from "../../../assets/img/erp.jpg"
import { TbSquareRoundedNumber1Filled } from "react-icons/tb";
import { TbSquareRoundedNumber2Filled } from "react-icons/tb";
import { TbSquareRoundedNumber3Filled } from "react-icons/tb";
import { TbSquareRoundedNumber4Filled } from "react-icons/tb";

const Enterprisefirstsection = () => {
  return (
    <div className=' m-5 web-text'>
      <Row>
        <Col>
        <p style={{textAlign:'justify'}}>
        We believe in a collaborative approach to software development, closely work with our clients to comprehend their objectives, challenges, and vision. Our team, comprising experienced developers, architects, and project managers, employs industry best practices and agile methodologies to deliver top-notch software solutions that enhance efficiency, productivity, and innovation. Leveraging our expertise in enterprise software development, we empower businesses to streamline operations, boost productivity, and foster growth.

</p>
<ul type="i">
  <li><TbSquareRoundedNumber1Filled style={{color:"#0d4f8c"}} /> Commited unique solutions delivered on time</li>
  <li><TbSquareRoundedNumber2Filled style={{color:"#0d4f8c"}} /> ideal mobile craft application at a very-effective rate</li>
  <li><TbSquareRoundedNumber3Filled style={{color:"#0d4f8c"}} /></li>
  <li><TbSquareRoundedNumber4Filled style={{color:"#0d4f8c"}} /> keep your software up-to-date and bug free with 24/7 support</li>
</ul> 
</Col>
<Col>
<img src={erpimage} style={{borderRadius:'10px'}}/>
</Col>
</Row>

</div>
  )
}

export default Enterprisefirstsection