import React from 'react'
import team1 from "../assets/img/Dharani.jpg"
import team2 from "../assets/img/madhu.jpg"
import team3 from "../assets/img/pavan.jpg"
import team4 from "../assets/img/surya.jpg"

const Exclusivemembers = () => {
  return (
    <div className='body-wrapper'> 
<section class="our-team-wrapper section-padding">
    <div class="container">
        <div class="row align-items-center mb-40">
            <div class="col-12 col-md-6">
                <div class="section-title">
                    <p>Exclusive Members</p>
                    <h1>Meet Our Experience Team Members</h1>
                </div>
            </div>
            {/* <div class="col-12 col-md-6 mt-4 mt-md-0 text-md-right">
                <a href="/Team" class="theme-btn off-white">View all Member <i class="fas fa-arrow-right"></i></a>
            </div> */}
        </div>

        <div class="team-members-list row">
            <div class="col-12 col-md-6 col-xl-3">
                <div class="single-team-member">
                    <div class="member-img bg-cover bg-center" style={{backgroundImage:`url(${team1})`}}>
                    </div>
                    <div class="member-bio">
                        <h4>Dharani </h4>
                        <p>UI/UX Designer</p>
                    </div>
                    {/* <div class="social-profile">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                        <a href="#"><i class="fab fa-youtube"></i></a>
                    </div> */}
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
                <div class="single-team-member active">
                    <div class="member-img bg-cover bg-center" style={{backgroundImage:`url(${team2})`}}>
                    </div>
                    <div class="member-bio">
                        <h4>Madhu Priya</h4>
                        <p>MERN Developer</p>
                    </div>
                    {/* <div class="social-profile">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                        <a href="#"><i class="fab fa-youtube"></i></a>
                    </div> */}
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
                <div class="single-team-member">
                    <div class="member-img bg-cover bg-center" style={{backgroundImage:`url(${team3})`}}>
                    </div>
                    <div class="member-bio">
                        <h4>Pavan R</h4>
                        <p>Dotnet Developer</p>
                    </div>
                    {/* <div class="social-profile">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                        <a href="#"><i class="fab fa-youtube"></i></a>
                    </div> */}
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
                <div class="single-team-member">
                    <div class="member-img bg-cover bg-center" style={{backgroundImage:`url(${team4})`}}>
                    </div>
                    <div class="member-bio">
                        <h4>Surya Prakash</h4>
                        <p>Flutter Developer</p>
                    </div>
                    {/* <div class="social-profile">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                        <a href="#"><i class="fab fa-youtube"></i></a>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
</section>  </div>
  )
}

export default Exclusivemembers