import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Maintenancetopbanner from './Maintenancetopbanner'
import Maintenanceimagesection from './Maintenanceimagesection'
import Footer from '../../../common components/Footer'
import Maintenancefirstsection from './Maintenancefirstsection'

const Maintenancewholepage = () => {
  return (
    <>
    <Topnavbar/>
    <Maintenancetopbanner/>
    <Maintenancefirstsection/>
    <Maintenanceimagesection/>
    <Footer/>
    </>
  )
}

export default Maintenancewholepage