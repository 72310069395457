import React from 'react'
import Topnavbar from '../common components/Topnavbar'
import Footer from '../common components/Footer'
import Contacttopbanner from './Contacttopbanner'
import Contactfirstsection from './Contactfirstsection'

const ContactWholePage = () => {
  return (
    <div>
        <Topnavbar/>
        <Contacttopbanner/>
        <Contactfirstsection/>
        <Footer/>

    </div>
  )
}

export default ContactWholePage