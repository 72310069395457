import React from 'react'
import Topnavbar from '../common components/Topnavbar'
import Footer from '../common components/Footer'
import pagebanner from "../assets/img/page-banner.jpg"
import team7 from "../assets/img/team/team7.jpg"
import funfactline from "../assets/img/funfact-line.png"
import brand1 from "../assets/img/brand/1.png"
import brand2 from "../assets/img/brand/2.png"
import brand3 from "../assets/img/brand/3.png"
import brand4 from "../assets/img/brand/4.png"
import brandb4 from "../assets/img/brand/b4.png"
import blog1 from "../assets/img/blog1.jpg"
import blog2 from "../assets/img/blog2.jpg"
import blog3 from "../assets/img/blog3.jpg"
import { IoDiamondSharp } from "react-icons/io5";
import { FaDraftingCompass } from "react-icons/fa";
import { BsStars } from "react-icons/bs";
import { LiaTrophySolid } from "react-icons/lia";

const Teamdetails = () => {
  return (
    <>
    <Topnavbar/>
    <section class="page-banner-wrap bg-cover" style={{backgroundImage:`url(${pagebanner})`}}>
        <div class="banner-text">techex</div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 col-12">
                    <div class="page-heading text-white">
                        <div class="page-title">
                            <h1>Team Details</h1>
                        </div>
                    </div>

                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="/">Home</a></li>
                          <li class="breadcrumb-item active" aria-current="page">team details</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    
    <section class="team-member-wrapper section-padding">
        <div class="container">
            <div class="member-details-wrap">
                <div class="row justify-content-between">

                    <div class="col-xl-4 col-12 col-lg-6">
                        <div class="member-card mr-0 mr-xl-3">
                            <div class="member-photo bg-cover" style={{backgroundImage:`url(${team7})`}}></div>
                            <div class="member-info">
                                <h4>Wallace S. Camacho</h4>
                                <p>Business Manager</p>
                                <div class="social-link">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-linkedin"></i></a>
                                    <a href="#"><i class="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 mt-5 col-lg-6 col-12 mt-lg-0">
                        <div class="member-about">
                            <div class="sec-title">
                                <h2>Abuot Me</h2>
                            </div>
                            <p>Sed ut perspiciatis unde omnis natus sit voluptatem accusa ntium doloremque ntium totam rem aperiameaqueipsa quae inventore veritatis quasi architect beatae vitae dicta sunt explicabo.</p>
                            <p>Doloremque lauda ntium totam reameaq ueipsa quae inventore veritatis</p>

                            <div class="sec-title mt-4">
                                <h2>Contact Me</h2>
                            </div>

                            <div class="member-contact-info mt-4">
                                <div class="single-contact-info d-flex">
                                    <div class="icon">
                                        <i class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div class="contact-info">
                                        <p>256 Elizaberth Ave, 90025</p>
                                    </div>
                                </div>
                                <div class="single-contact-info d-flex">
                                    <div class="icon">
                                        <i class="fas fa-envelope-open-text"></i>
                                    </div>
                                    <div class="contact-info">
                                        <p>info@supportexam.com</p>
                                    </div>
                                </div>
                                <div class="single-contact-info d-flex">
                                    <div class="icon">
                                        <i class="fas fa-phone"></i>
                                    </div>
                                    <div class="contact-info">
                                        <p>+012 (345) 678 99</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 mt-5 col-lg-12 col-12 mt-xl-0">
                        <div class="member-skills">
                            <div class="sec-title">
                                <h2>Skills</h2>
                            </div>
                            <p>Accusa ntium doloremque ntium totam rem aperiameaqueipsa quae  inventore veritatis quasi architect beatae</p>

                            <div class="single-skill-bar">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h4>Product Engineering</h4>
                                    <span>95%</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" style={{width:"95%"}}></div>
                                </div>
                            </div>
                            <div class="single-skill-bar">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h4>Digital Solutions</h4>
                                    <span>85%</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" style={{width:"85%"}}></div>
                                </div>
                            </div>
                            <div class="single-skill-bar">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h4>IT Consultancy</h4>
                                    <span>90%</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" style={{width:"90%"}}></div>
                                </div>
                            </div>
                            <div class="single-skill-bar">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h4>UX/UI Strategy</h4>
                                    <span>70%</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" style={{width:"70%"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>   
    <section class="funfact-wrapper no-bg text-white">
        <div class="container">
            <div class="funfact-content-grid bg-cover" style={{backgroundImage:`url(${funfactline})`}}>
                <div class="single-funfact-item">
                    <div class="icon">
                        <IoDiamondSharp />
                    </div>
                    <h3>368</h3>
                    <p>Project Completed</p>
                </div>
                <div class="single-funfact-item">
                    <div class="icon">
                        <FaDraftingCompass />
                    </div>
                    <h3>785</h3>
                    <p>Expert Consultants</p>
                </div>
                <div class="single-funfact-item">
                    <div class="icon">
                        <BsStars/>
                    </div>
                    <h3>896</h3>
                    <p>5 Stars Rating</p>
                </div>
                <div class="single-funfact-item">
                    <div class="icon">
                        <LiaTrophySolid/>
                    </div>
                    <h3>125</h3>
                    <p>Awards Winning</p>
                </div>
            </div>
        </div>
    </section>  
    <section class="blog-section section-padding bg-contain">
        <div class="container">
            <div class="row mb-30">
                <div class="col-12 col-lg-12">
                    <div class="section-title style-3 text-center">
                        <span>blogs</span>
                        <p>articles & tipes</p>
                        <h1>Latest News & Blog</h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="single-blog-card style-2">
                        <div class="blog-featured-thumb bg-cover" style={{backgroundImage:`url(${blog1})`}}></div>
                        <div class="content">
                            <div class="post-author">
                                <a href="/newsdetail"><i class="fal fa-user-circle"></i> Michael K. Steven</a>
                            </div>
                            <h3><a href="/newsdetail">Make Honest Design Work As For Digital Business.</a></h3>
                            <div class="btn-link-share">
                                <a href="/newsdetail" class="theme-btn minimal-btn">read more <i class="fas fa-arrow-right"></i></a>
                                <a href="#"><i class="fal fa-share-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="single-blog-card style-2">
                        <div class="blog-featured-thumb bg-cover" style={{backgroundImage:`url(${blog2})`}}></div>
                        <div class="content">
                            <div class="post-author">
                                <a href="/newsdetail"><i class="fal fa-user-circle"></i> Salman Ahmed</a>
                            </div>
                            <h3><a href="/newsdetail">Should You Do When A Webs Design Trend Becomes</a></h3>
                            <div class="btn-link-share">
                                <a href="/newsdetail" class="theme-btn minimal-btn">read more <i class="fas fa-arrow-right"></i></a>
                                <a href="#"><i class="fal fa-share-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="single-blog-card style-2">
                        <div class="blog-featured-thumb bg-cover" style={{backgroundImage:`url(${blog3})`}}></div>
                        <div class="content">
                            <div class="post-author">
                                <a href="/newsdetail"><i class="fal fa-user-circle"></i> Michael K. Steven</a>
                            </div>
                            <h3><a href="/newsdetail">How To Space And Kern Punct Marks Other Symbols</a></h3>
                            <div class="btn-link-share">
                                <a href="/newsdetail" class="theme-btn minimal-btn">read more <i class="fas fa-arrow-right"></i></a>
                                <a href="#"><i class="fal fa-share-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="client-brand-logo-wrap">
        <div class="container">
            <div class="brand-carousel-active pt-60 pb-60 d-flex justify-content-between owl-carousel">
                <div class="single-client">
                    <img src={{brand1}} alt=""/>
                </div>
                <div class="single-client">
                    <img src={{brand2}} alt=""/>
                </div>
                <div class="single-client">
                    <img src={{brand3}} alt=""/>
                </div>
                <div class="single-client">
                    <img src={{brand4}} alt=""/>
                </div>
                <div class="single-client">
                    <img src={{brandb4}} alt=""/>
                </div>
            </div>
        </div>
    </div>
    <Footer/>

    </>
  )
}

export default Teamdetails