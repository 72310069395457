import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import praathee1 from "../assets/img/Frame218.png";
import praathee2 from "../assets/img/Frame225.png";
import praathee3 from "../assets/img/Cloudolutions.png"
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { BsArrowLeftSquareFill } from "react-icons/bs";

function Homepagetopbanner() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div style={{ position: 'relative', marginTop: "-20px", height: '400px' }}>
      <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
        <Carousel.Item>
          <img src={praathee1} alt="Slide 1" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={praathee2} alt="Slide 2" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={praathee3} alt="Slide 3" />
        </Carousel.Item>
      </Carousel>
      <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
        <BsArrowLeftSquareFill
          style={{ color: '#ffdcb7', fontSize: '30px', marginRight: '10px', cursor: 'pointer' }}
          onClick={() => setIndex(index === 0 ? 2 : index - 1)} // Adjust the index accordingly
        />
        <BsFillArrowRightSquareFill
          style={{ color: '#0a6aa0', fontSize: '30px', cursor: 'pointer' }}
          onClick={() => setIndex(index === 2 ? 0 : index + 1)} // Adjust the index accordingly
        />
      </div>
    </div>
  );
}

export default Homepagetopbanner;
