import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Topnavbar from '../common components/Topnavbar';
import Footer from '../common components/Footer';
import pagebanner from "../assets/img/Careers.png"
import { MdKeyboardArrowRight } from "react-icons/md";
import { Row ,Col} from 'react-bootstrap';
import careerpageimage from "../assets/img/careerimage.jpg"


export const ContactUs = () => {
  



  return (
    <>
    {/* <div className='body-wrapper'> */}
    {/* <Topnavbar/> */}
    {/* <section class="page-banner-wrap bg-cover" style={{backgroundImage:`url(${pagebanner})`}}>
    <div class="banner-text"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 col-12">
                <div class="page-heading text-white">
                    <div class="page-title">
                        <h1>
                          &nbsp;
                        </h1>
                    </div>
                </div> */}

                {/* <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="/"></a></li> */}
                      {/* <MdKeyboardArrowRight style={{color:"white"}} size={24}/> */}
                      {/* <li class="breadcrumb-item active" aria-current="page">Careers</li> */}
                    {/* </ol>
                </nav>
            </div>
        </div>
    </div>
</section> */}
    {/* <div className='container'>
      <div className="row section-padding pb-0 ">
        <div className="col-12 text-center mb-20">
          <div className="section-title">
            <p>Send us a message</p>
            <h1>Don’t Hesitate To Contact Us <br/> Say Hello or Message</h1>
          </div>
        </div> */}
{/* 
        <div className="col-12 col-lg-12">
          <div className="contact-form">                                                        
            <form action="" className="row conact-form" ref={form} onSubmit={sendEmail}>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="fname">Full Name</label>
                  <input type="text" id="fname" placeholder="Enter Name" name="user_name" required />                                         
                </div>
              </div>                            
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="email">Email Address</label>
                  <input type="email" id="email" placeholder="Enter Email Address" name="user_email" required />                                         
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="phone">Phone Number</label>
                  <input type="text" id="phone" placeholder="Enter Number" number="user_number" required />                                         
                </div>
              </div>                                      
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" id="subject" placeholder="Enter Subject" subject="user_subject" required />                                         
                </div>
              </div>                                      
              <div className="col-md-12 col-12">
                <div className="single-personal-info">
                  <label htmlFor="message">Enter Message</label>
                  <textarea id="message" placeholder="Enter message" message="message" required></textarea>                                        
                </div>
              </div>                                      
              <div className="col-md-12 col-12 text-center">
                <button type="submit" className="theme-btn">Send Message <i className="fas fa-arrow-right"></i></button>
              </div>                                      
            </form>
          </div>
        </div>
      </div>
      </div>
      <Footer/>
      </div> */}
      <Topnavbar/>
      <div className='container'>
        <Row>
          <Col xs={12} md={5} sm={12} lg={5} >
        <p style={{textAlign:'justify'}}>Praathee helps you grow beyond set designations and pre-defined roles to realize your true potential.

We are always on the lookout of professionals with sound IT knowledge, passion and urge to think out of the box. We are also keen on putting in place a multi-disciplinary team. So if you are a go-getter in your domain and are keen to take up new challenges, please mark your resume to                             <p><a href="mailto:hr@praathee.com" className='text-decoration-none'> &nbsp;hr@praathee.com</a></p>
Our HR team will get in touch with you if your skill set matches our present-day requirements.</p>
</Col>
<Col xs={12} md={6} sm={12} lg={6} style={{marginLeft:'60px' ,marginBottom:"20px"}}>
<img src={careerpageimage} alt='careerpageimage' style={{borderRadius:'10px',opacity:'.8'}}></img>
</Col>

</Row>
      </div>
      <Footer/>

    </>
  );
};
