import React from 'react'
import { Row,Col } from 'react-bootstrap'
import mobimage from "../../../assets/img/mobileappdevelopmentparaimage.jpg"
import { TbSquareRoundedNumber1Filled } from "react-icons/tb";
import { TbSquareRoundedNumber2Filled } from "react-icons/tb";
import { TbSquareRoundedNumber3Filled } from "react-icons/tb";
import { TbSquareRoundedNumber4Filled } from "react-icons/tb";



const Mobilefirstsection = () => {
  return (
    <div className=' m-5 '>
      <Row>
        <Col>
        <p style={{textAlign:'justify'}} className='web-text'> <span style={{color:'#006EA2'}}>Praathee</span> Media is focused in providing tailored mobile applications to meet your specific needs, we infuse innovative ideas to deliver high-quality, user-friendly solutions. Our mobile app development service is dedicated to propelling your growth journey, offering unique solutions with a commitment to timely delivery. As a seasoned mobile application development company in India, we craft the , streamlining a seamless path for your clients to access products or services that cater to your business needs.
</p>
<ul type="i">
  <li><TbSquareRoundedNumber1Filled style={{color:"#0d4f8c"}} /> Commited unique solutions delivered on time</li>
  <li><TbSquareRoundedNumber2Filled style={{color:"#0d4f8c"}} /> ideal mobile craft application at a very-effective rate</li>
  <li><TbSquareRoundedNumber3Filled style={{color:"#0d4f8c"}} /></li>
  <li><TbSquareRoundedNumber4Filled style={{color:"#0d4f8c"}} /> keep your software up-to-date and bug free with 24/7 support</li>
</ul> 
</Col>
<Col>
<img src={mobimage} alt='' style={{borderRadius:'10px'}}/>
</Col>
        </Row>

    </div>
  )
}

export default Mobilefirstsection