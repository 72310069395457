import React from 'react'
import faqhome2 from "../assets/img/faq_home_2.jpg"
import whycooseus from "../assets/img/Retail-IT-Solutions1.png"
import { Accordion, Card } from 'react-bootstrap';


const Whychooseus = () => {
  return (
    <div className='body-wrapper'> 
<section class="faq-section section-padding">
    <div class="faq-bg bg-cover d-none d-lg-block" style={{backgroundImage:`url(${whycooseus})`}}></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-6 offset-lg-6 offset-xl-7">
                <div class="col-12 col-lg-12 mb-40">
                    <div class="section-title">
                        <p>Why choose us</p>
                        <h1>Commitment  to Quality </h1>
                    </div>
                </div>

                <div class="faq-content">
                    <div class="faq-accordion">
                        <div id="accordion" class="accordion">
                           
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className='card'>
        <Accordion.Header class="collapsed mb-0 text-capitalize">How can your company help improve our IT infrastructure?</Accordion.Header>
        <Accordion.Body className='card-body'>
        we specialize in enhancing IT infrastructure to optimize performance, security, and scalability for our clients. Whether you're a small startup or a large enterprise, our team of experienced professionals will work closely with you to assess your current infrastructure, identify areas for improvement, and implement tailored solutions to meet your specific needs
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className='card'>
        <Accordion.Header class="collapsed mb-0 text-capitalize">How do you ensure data security for your clients?</Accordion.Header>
        <Accordion.Body className='card-body'>
        We prioritize data security through advanced encryption methods, robust firewall protection, regular security audits, comprehensive employee training, and adherence to industry standards such as GDPR, HIPAA, and PCI DSS. By employing a multi-layered approach and staying vigilant against emerging threats, we ensure that our clients' sensitive information remains safeguarded at all times, providing them with the peace of mind they need to focus on their core business objectives.
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className='card'>
        <Accordion.Header class="collapsed mb-0 text-capitalize">What is your pricing structure for design and development services?</Accordion.Header>
        <Accordion.Body className='card-body'>
        Our pricing structure varies depending on the scope and complexity of the project. We offer competitive rates for our services and can provide detailed estimates or quotes tailored to your specific requirements. We believe in transparency and strive to ensure that our pricing is fair and transparent, with no hidden fees or surprises.
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
                            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className='card'>
        <Accordion.Header class="collapsed mb-0 text-capitalize"> What is your approach to project management?</Accordion.Header>
        <Accordion.Body className='card-body'>
        We adopt a collaborative approach to project management, where we work closely with our clients throughout the project lifecycle. We begin by thoroughly understanding your requirements and objectives, then create a detailed project plan with clear milestones and deliverables. Our team maintains open communication channels, provides regular updates, and seeks feedback to ensure that the project stays on track and meets your expectations.
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>  
</div>
  )
}

export default Whychooseus