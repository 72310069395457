import React from 'react'
import Webtopbanner from './Webtopbanner'
import Webfirstsection from './Webfirstsection'
import Webimagesection from './Webimagesection'
import Topnavbar from '../../../common components/Topnavbar'
import Footer from '../../../common components/Footer'

const Webwholepage = () => {
  return (
    <>
    <Topnavbar/>
    <Webtopbanner/>
    <Webfirstsection />
    <Webimagesection/>
    <Footer/>
    </>
  )
}

export default Webwholepage