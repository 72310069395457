import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Cloudtopbanner from './Cloudtopbanner'
import Cloudfirstsection from './Cloudfirstsection'
import Cloudimagesection from './Cloudimagesection'
import Footer from '../../../common components/Footer'

const Cloudwholepage = () => {
  return (
    <>
    <Topnavbar/>
    <Cloudtopbanner/>
    <Cloudfirstsection/>
    <Cloudimagesection/>
    <Footer/>
    </>
  )
}

export default Cloudwholepage