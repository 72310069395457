import React from 'react'
import { Row,Col } from 'react-bootstrap'
import maintenanceimage from "../../../assets/img/supportmaintenance.jpg"
import { TbSquareRoundedNumber1Filled } from "react-icons/tb";
import { TbSquareRoundedNumber2Filled } from "react-icons/tb";
import { TbSquareRoundedNumber3Filled } from "react-icons/tb";
import { TbSquareRoundedNumber4Filled } from "react-icons/tb";

const Maintenancefirstsection = () => {
  return (
    <div className=' m-5 web-text'>
      <Row>
        <Col>
        
    <p style={{textAlign:'justify'}} >We understand that maintaining and supporting software is crucial for its optimal performance and longevity. That's why we offer a range of services designed to keep your software running smoothly and efficiently, allowing you to focus on your core business activities.

</p>
<ul type="i">
  <li><TbSquareRoundedNumber1Filled style={{color:"#0d4f8c"}} /> Commited unique solutions delivered on time</li>
  <li><TbSquareRoundedNumber2Filled style={{color:"#0d4f8c"}} /> ideal mobile craft application at a very-effective rate</li>
  <li><TbSquareRoundedNumber3Filled style={{color:"#0d4f8c"}} /></li>
  <li><TbSquareRoundedNumber4Filled style={{color:"#0d4f8c"}} /> keep your software up-to-date and bug free with 24/7 support</li>
</ul> 
</Col>
<Col>
<img src={maintenanceimage} style={{borderRadius:'10px'}}/>
</Col>
</Row>

</div>
  )
}

export default Maintenancefirstsection