import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Blockchaintopbanner from './Blockchaintopbanner'
import Blockchainfirstsection from './Blockchainfirstsection'
import Footer from '../../../common components/Footer'
import Blockchainimagesection from './Blockchainimagesection'

const Blockchainwholepage = () => {
  return (
    <>
    <Topnavbar/>
    <Blockchaintopbanner/>
    <Blockchainfirstsection/>
    <Blockchainimagesection/>
    <Footer/>
    </>
  )
}

export default Blockchainwholepage