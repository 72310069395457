import React from 'react'
import webtopbanner from "../../../assets/img/web.png";
import { MdKeyboardArrowRight } from "react-icons/md";


const Webtopbanner = () => {
  return (
    <div className='body-wrapper'>
    <section class="page-banner-wrap bg-cover" style={{backgroundImage:`url(${webtopbanner})`}}>
<div class="banner-text"></div>
<div class="container">
    <div class="row align-items-center">
        <div class="col-md-12 col-12">
            <div class="page-heading text-white">
                <div class="page-title">
                    <h1>&nbsp;</h1>
                </div>
            </div>

            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"></a></li>
                  {/* <MdKeyboardArrowRight style={{color:"white"}} size={24}/> */}
                  {/* <li class="breadcrumb-item active" aria-current="page"></li> */}
                </ol>
            </nav>
        </div>
    </div>
</div>
</section>
</div>
  )
}

export default Webtopbanner