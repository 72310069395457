import React from 'react'
import { Row,Col } from 'react-bootstrap'
import itstaffingserviceparaimage from "../../../assets/img/itstaffingandserviceparaimage.jpg"
import { TbSquareRoundedNumber1Filled } from "react-icons/tb";
import { TbSquareRoundedNumber2Filled } from "react-icons/tb";
import { TbSquareRoundedNumber3Filled } from "react-icons/tb";
import { TbSquareRoundedNumber4Filled } from "react-icons/tb";

const Itstaffingfirstsection = () => {
  return (
    <div className=' m-5 web-text'>
      <Row>
        <Col>
    <p style={{textAlign:'justify'}}>Your premier destination for IT staffing and support services. We recognize the pivotal role skilled IT professionals play in the success of your business, which is why we are committed to providing top-notch staffing solutions and unparalleled support to meet your organization's needs

</p>
<ul type="i">
  <li><TbSquareRoundedNumber1Filled style={{color:"#0d4f8c"}} /> Commited unique solutions delivered on time</li>
  <li><TbSquareRoundedNumber2Filled style={{color:"#0d4f8c"}} /> ideal mobile craft application at a very-effective rate</li>
  <li><TbSquareRoundedNumber3Filled style={{color:"#0d4f8c"}} /></li>
  <li><TbSquareRoundedNumber4Filled style={{color:"#0d4f8c"}} /> keep your software up-to-date and bug free with 24/7 support</li>
</ul> 
</Col>
<Col>
<img src={itstaffingserviceparaimage} style={{borderRadius:'10px',opacity:"0.82"}}/>
</Col>
</Row>

</div>
  )
}

export default Itstaffingfirstsection